import { Model } from '@app/Model.js';
import { BaseUser } from '../User';
import { matomoTracker } from '../matomoTracker';
import { i18n } from '@i18n';

class TalentUser extends BaseUser {
	get user() {
		return super.user as APItalent;
	}
	set user(updatedUser: APItalent) {
		super.user = updatedUser;
	}
	async userObjectReceived(user: APItalent): Promise<void> {
		await super.userObjectReceived(user);
		const { newlyOnboarded = 0 } = user;
		if (newlyOnboarded) {
			matomoTracker.conversionTrack('onboarding-complete');
			await Model.data.deleteNewlyObFlag();
		}
	}

	get isOnboarded(): boolean {
		return !!(
			this.user?.emailverified &&
			(this.user?.onboardTS || this.user?.employment === 'empl')
		);
	}

	get spokenLangs(): string[][] {
		return this.user?.spokenLangs
			? // todo remove filter() once BE removed dialects
				this.user?.spokenLangs
					.sort()
					.map((l: string) => l.split(':'))
					.filter((l) => !/\d/u.test(l[0]))
			: [];
	}

	get speedtestResults(): number[] {
		return this.user?.speedtest?.includes(':')
			? this.user?.speedtest?.split(':').map((n: string) => parseInt(n))
			: [];
	}

	get speedtestValuation(): number[] {
		return this.speedtestResults?.length
			? this.speedtestResults?.map((value: number, i: number) => {
					let percentage = value / [20, 10, 100, 40][i];
					percentage = (i > 1 ? 1 - percentage : percentage) * 100;
					return Math.max(Math.min(percentage, 100), 0);
				})
			: new Array(4).fill(null);
	}

	get hasBillingAddress(): boolean {
		return this.user?.addresses
			? this.user?.addresses.some(
					(a: APIaddress) =>
						a.accepts.includes('billing') || a.types.includes('billing'),
				)
			: false;
	}

	get hasWorkAddress(): boolean {
		return this.user?.addresses
			? this.user?.addresses.some(
					(a: APIaddress) =>
						a.accepts.includes('work') || a.types.includes('work'),
				)
			: false;
	}

	get hasValidTaxnumber(): boolean {
		return (
			!!this.user?.taxNumber &&
			(!!this.user?.vatIdMissingReason || this.user?.euVatIdValid === 1)
		);
	}

	get hasValidBankaccount(): boolean {
		return (
			this.user?.swiftOK !== 0 &&
			!!(this.user?.iban || this.user?.bankAccNum) &&
			this.user?.ibanOK !== 0
		);
	}

	get hasAcceptedBillingAddress(): boolean {
		return this.user?.addresses
			? this.user?.addresses.some((a: APIaddress) =>
					a.accepts.includes('billing'),
				)
			: false;
	}

	get hasTaxInfo(): boolean {
		return this.user?.taxModell !== 'unknown' || this.user?.country !== 'DE';
	}

	get isTaxTransparencyCompliant(): boolean {
		return !this.user?.billingAddressInEU || !!this.user?.gender;
	}

	get hasPassport(): boolean {
		return (
			this.user?.passportverify !== 'none' &&
			this.user?.passportverify !== 'denied'
		);
	}

	get hasScriptedEvaluation(): boolean {
		return this.user?.scriptedEvaluation === 'verified';
	}

	get hasNonScriptedEvaluation(): boolean {
		return this.user?.nonScriptedEvaluation === 'verified';
	}

	get hasListeningEvaluation(): boolean {
		return this.user?.listeningEvaluation === 'verified';
	}

	get hasSignedPrivacyDocs(): boolean {
		return (
			Number(this.user?.signedPrivVersion) ===
			Number(i18n.statics?.minPrivVersion)
		);
	}

	get isEmployed(): boolean {
		return this.user?.employment === 'empl';
	}

	get isHardlocked(): boolean {
		return this.user?.hardlocked;
	}

	get obStep(): 'countdown' | 'documents' | '' {
		if (!this.isOnboarded) return '';
		if (this.user?.onboardPreferred) return 'countdown';
		if (
			this.user?.phoneMobile &&
			this.hasBillingAddress &&
			this.hasWorkAddress
		) {
			return 'documents';
		}
		return '';
	}

	getName(user = null): string {
		const { name, pseudonym } = user || this.user;
		return `${name}${pseudonym ? ` (${pseudonym})` : ''}`;
	}
}

const User = new TalentUser();
export { User };
